import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import RelatedServices from "../../components/RelatedServices";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";

import ServicesData from "../../data/Services/services";

import NpcBanner from "../../assets/img/service/npc_banner.jpg";
import NpeBanner from "../../assets/img/service/electrical_banner.png";
import NpisBanner from "../../assets/img/service/npis_banner.jpg";
import NpsBanner from "../../assets/img/service/npe1.jpeg";

const ServiceDetails = () => {
  const serviceID = new URLSearchParams(window.location.search).get("id");
  const service = ServicesData.find(
    (serviceItem) => serviceItem.id === parseInt(serviceID)
  );
  const currentService = ServicesData.indexOf(service);
  const otherServices = ServicesData.filter(
    (serviceItem) => serviceItem.id !== parseInt(serviceID)
  );
  let prevService;
  let nextService;
  currentService === 0
    ? (prevService = ServicesData[currentService])
    : (prevService = ServicesData[currentService - 1]);
  currentService + 1 === ServicesData.length
    ? (nextService = ServicesData[currentService])
    : (nextService = ServicesData[currentService + 1]);

  const bannerItem = () => {
    switch (service.shortCom) {
      case "npc": return NpcBanner;
      case "npis": return NpisBanner;
      case "npe": return NpeBanner;
      default: {
        return NpsBanner;
      }
    }
  };

  return (
    <Fragment>
      <PageHeader
        bgImg={bannerItem()}
        title={service.title}
        content={service.shortDesc}
      />
      <PageWrapper classes="sm-top service-details-wrapper">
        <ServiceContentWrap
          service={service}
          totalService={ServicesData.length}
          nextService={nextService}
          prevService={prevService}
        />

        <Sidebar classes={"col-lg-4 mtn-40"}>
          <SidebarItem title="Services">
            <List classes="service-list">
              {ServicesData.map((serviceItem) => (
                <LI key={serviceItem.id}>
                  <a
                    href={`${
                      process.env.PUBLIC_URL +
                      `/service/${serviceItem.title
                        .split(" ")
                        .join("-")
                        .toLowerCase()}?id=${serviceItem.id}`
                    }`}
                  >
                    {serviceItem.title}
                  </a>
                </LI>
              ))}
            </List>
          </SidebarItem>
        </Sidebar>
      </PageWrapper>

      <RelatedServices data={otherServices} />
    </Fragment>
  );
};

export default ServiceDetails;
