import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

function Member(props) {
    const teamMemberURl = `/team-member/${props.name.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);


    return (
        <Fragment>
            {props.type === 'page' ? (
                <div className="col-sm-6 col-lg-3">
                    <button type="button" onClick={() => setIsOpen(true)}>
                        <div className="team-mem-item">
                            <figure className="member-pic">
                                <img src={props.profilePic} alt={props.name} />
                            </figure>
                            <div className="member-info">
                                <h5>{props.name} </h5>
                                <span className="designation">{props.designation}</span>
                            </div>
                        </div>
                    </button>
                </div>
            ) : (
                <div className="team-mem-item">
                    <figure className="member-pic">
                        <Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}>
                            <img src={require('../../../assets/img/' + props.profilePic)} alt={props.name} />
                        </Link>
                    </figure>
                    <div className="member-info">
                        <h5><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`} className="stretched-link">{props.name}</Link></h5>
                        <span className="designation">{props.designation}</span>
                    </div>
                </div>
            )
            }
            {isOpen && (
                <Lightbox
                    mainSrc={props.images[photoIndex].src}
                    imageTitle={props.designation}
                    imageCaption={props.images[photoIndex].caption}
                    nextSrc={props.images[(photoIndex + 1) % props.images.length]}
                    prevSrc={props.images[(photoIndex + props.images.length - 1) % props.images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + props.images.length - 1) % props.images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % props.images.length)
                    }
                />
            )}
        </Fragment>
    );
}

export default Member;