import React, { Component } from 'react';
import logo from '../../assets/img/logoNationPowerWhite.png'

class Logo extends Component {
    render() {
        return (
            <div className="logo-area">
                <a href={`${process.env.PUBLIC_URL}`}><img src={logo} className="logo" alt="Nation-Power-Logo" /></a>
            </div>
        );
    }
}

export default Logo;