import Nps1 from '../../assets/img/gallery/nps/nps1.jpg';
import Nps2 from '../../assets/img/gallery/nps/nps2.jpg';
import Nps3 from '../../assets/img/gallery/nps/nps3.jpg';
import Nps4 from '../../assets/img/gallery/nps/nps4.jpg';
import Nps5 from '../../assets/img/gallery/nps/nps5.jpg';
import Nps6 from '../../assets/img/gallery/nps/nps6.jpg';
import Nps7 from '../../assets/img/gallery/nps/nps7.jpg';
import Nps8 from '../../assets/img/gallery/nps/nps8.jpg';
import Nps9 from '../../assets/img/gallery/nps/nps9.jpg';
import Nps10 from '../../assets/img/gallery/nps/nps10.jpg';
import Nps11 from '../../assets/img/gallery/nps/nps11.jpg';
import Nps12 from '../../assets/img/gallery/nps/nps12.jpg';
import Nps13 from '../../assets/img/gallery/nps/nps13.jpg';
import Nps14 from '../../assets/img/gallery/nps/nps14.png';
import Nps15 from '../../assets/img/gallery/nps/nps15.png';
import Nps16 from '../../assets/img/gallery/nps/nps16.png';
import Nps17 from '../../assets/img/gallery/nps/nps17.png';
import Nps18 from '../../assets/img/gallery/nps/nps18.png';
import Nps19 from '../../assets/img/gallery/nps/nps19.png';
import ThumbNps from '../../assets/img/gallery/nps/thumbNps.png';

import Npc1 from '../../assets/img/gallery/npc/npc1.png';
import Npc2 from '../../assets/img/gallery/npc/npc2.png';
import Npc3 from '../../assets/img/gallery/npc/npc3.png';
import Npc4 from '../../assets/img/gallery/npc/npc4.png';
import Npc5 from '../../assets/img/gallery/npc/npc5.png';
import Npc6 from '../../assets/img/gallery/npc/npc6.png';
import Npc7 from '../../assets/img/gallery/npc/npc7.png';
import Npc8 from '../../assets/img/gallery/npc/npc8.png';
import Npc9 from '../../assets/img/gallery/npc/npc9.jpg';
import Npc10 from '../../assets/img/gallery/npc/npc10.jpg';
import Npc11 from '../../assets/img/gallery/npc/npc11.jpg';
import Npc12 from '../../assets/img/gallery/npc/npc12.jpg';
import Npc13 from '../../assets/img/gallery/npc/npc13.jpg';
import Npc14 from '../../assets/img/gallery/npc/npc14.jpg';
import Npc15 from '../../assets/img/gallery/npc/npc15.jpg';
import Npc16 from '../../assets/img/gallery/npc/npc16.jpg';
import Npc17 from '../../assets/img/gallery/npc/npc17.jpg';
import Npc18 from '../../assets/img/gallery/npc/npc18.jpg';
import Npc19 from '../../assets/img/gallery/npc/npc19.jpg';
import Npc20 from '../../assets/img/gallery/npc/npc20.jpg';
import Npc21 from '../../assets/img/gallery/npc/npc21.jpg';
import Npc22 from '../../assets/img/gallery/npc/npc22.jpg';
import Npc23 from '../../assets/img/gallery/npc/npc23.jpg';
import Npc24 from '../../assets/img/gallery/npc/npc24.jpg';
import Npc25 from '../../assets/img/gallery/npc/npc25.jpg';
import Npc26 from '../../assets/img/gallery/npc/npc26.jpg';
import Npc27 from '../../assets/img/gallery/npc/npc27.jpg';
import Npc28 from '../../assets/img/gallery/npc/npc28.jpg';
import Npc29 from '../../assets/img/gallery/npc/npc29.jpg';
import Npc30 from '../../assets/img/gallery/npc/npc30.jpg';
import Npc31 from '../../assets/img/gallery/npc/npc31.jpg';
import Npc32 from '../../assets/img/gallery/npc/npc32.jpg';
import Npc33 from '../../assets/img/gallery/npc/npc33.jpg';
import ThumbNpc from '../../assets/img/gallery/npc/thumbNpc.png';

import Npe1 from '../../assets/img/gallery/npe/npe1.png';
import Npe2 from '../../assets/img/gallery/npe/npe2.png';
import Npe3 from '../../assets/img/gallery/npe/npe3.png';
import Npe4 from '../../assets/img/gallery/npe/npe4.png';
import Npe5 from '../../assets/img/gallery/npe/npe5.png';
import Npe6 from '../../assets/img/gallery/npe/npe6.png';
import Npe7 from '../../assets/img/gallery/npe/npe7.png';
import Npe8 from '../../assets/img/gallery/npe/npe8.png';
import Npe9 from '../../assets/img/gallery/npe/npe9.png';
import Npe10 from '../../assets/img/gallery/npe/npe10.png';
import Npe11 from '../../assets/img/gallery/npe/npe11.png';
import Npe12 from '../../assets/img/gallery/npe/npe12.jpg';
import Npe13 from '../../assets/img/gallery/npe/npe13.jpg';
import Npe14 from '../../assets/img/gallery/npe/npe14.jpg';
import Npe15 from '../../assets/img/gallery/npe/npe15.jpg';
import Npe16 from '../../assets/img/gallery/npe/npe16.jpg';
import Npe17 from '../../assets/img/gallery/npe/npe17.jpg';
import Npe18 from '../../assets/img/gallery/npe/npe18.jpg';
import Npe19 from '../../assets/img/gallery/npe/npe19.jpg';
import Npe20 from '../../assets/img/gallery/npe/npe20.jpg';
import Npe21 from '../../assets/img/gallery/npe/npe21.jpg';
import Npe22 from '../../assets/img/gallery/npe/npe22.jpg';
import Npe23 from '../../assets/img/gallery/npe/npe23.jpg';
import Npe24 from '../../assets/img/gallery/npe/npe24.jpg';
import Npe25 from '../../assets/img/gallery/npe/npe25.jpg';
import Npe26 from '../../assets/img/gallery/npe/npe26.jpg';
import Npe27 from '../../assets/img/gallery/npe/npe27.jpg';
import Npe28 from '../../assets/img/gallery/npe/npe28.jpg';
import Npe29 from '../../assets/img/gallery/npe/npe29.jpg';
import Npe30 from '../../assets/img/gallery/npe/npe30.jpg';
import Npe31 from '../../assets/img/gallery/npe/npe31.jpg';
import Npe32 from '../../assets/img/gallery/npe/npe32.jpg';
import Npe33 from '../../assets/img/gallery/npe/npe33.jpg';
import Npe34 from '../../assets/img/gallery/npe/npe34.jpg';
import Npe35 from '../../assets/img/gallery/npe/npe35.jpg';
import Npe36 from '../../assets/img/gallery/npe/npe36.jpg';
import Npe37 from '../../assets/img/gallery/npe/npe37.jpg';
import Npe38 from '../../assets/img/gallery/npe/npe38.jpg';
import Npe39 from '../../assets/img/gallery/npe/npe39.jpg';
import Npe40 from '../../assets/img/gallery/npe/npe40.jpg';
import Npe41 from '../../assets/img/gallery/npe/npe41.jpg';
import Npe42 from '../../assets/img/gallery/npe/npe42.jpg';
import Npe43 from '../../assets/img/gallery/npe/npe43.jpg';
import ThumbNpe from '../../assets/img/gallery/npe/thumbNpe.png';

import Npis1 from '../../assets/img/gallery/npis/npis1.jpg';
import Npis2 from '../../assets/img/gallery/npis/npis2.jpg';
import Npis3 from '../../assets/img/gallery/npis/npis3.jpg';
import Npis4 from '../../assets/img/gallery/npis/npis4.jpg';
import Npis5 from '../../assets/img/gallery/npis/npis5.jpg';
import Npis6 from '../../assets/img/gallery/npis/npis6.jpg';
import Npis7 from '../../assets/img/gallery/npis/npis7.jpg';
import Npis8 from '../../assets/img/gallery/npis/npis8.jpg';
import Npis9 from '../../assets/img/gallery/npis/npis9.jpg';
import Npis10 from '../../assets/img/gallery/npis/npis10.jpg';
import Npis11 from '../../assets/img/gallery/npis/npis11.jpg';
import Npis12 from '../../assets/img/gallery/npis/npis12.jpg';
import Npis13 from '../../assets/img/gallery/npis/npis13.jpg';
import Npis14 from '../../assets/img/gallery/npis/npis14.jpg';
import Npis15 from '../../assets/img/gallery/npis/npis15.jpg';
import Npis16 from '../../assets/img/gallery/npis/npis16.jpg';
import Npis17 from '../../assets/img/gallery/npis/npis17.jpg';
import Npis18 from '../../assets/img/gallery/npis/npis18.jpg';
import Npis19 from '../../assets/img/gallery/npis/npis19.jpg';
import Npis20 from '../../assets/img/gallery/npis/npis20.jpg';
import Npis21 from '../../assets/img/gallery/npis/npis21.jpg';
import Npis22 from '../../assets/img/gallery/npis/npis22.jpg';
import Npis23 from '../../assets/img/gallery/npis/npis23.jpg';
import Npis24 from '../../assets/img/gallery/npis/npis24.jpg';
import Npis25 from '../../assets/img/gallery/npis/npis25.jpg';
import ThumbNpis from '../../assets/img/gallery/npis/thumbNpis.png';



const TeamData = [
    {
        "id": 1,
        "name": "",
        "designation": "Electrical & Instrumentation",
        "profilePic": ThumbNpe,
        "images": [
            { "src": Npe1, "caption": "" },
            { "src": Npe2, "caption": "" },
            { "src": Npe3, "caption": "" },
            { "src": Npe4, "caption": "" },
            { "src": Npe5, "caption": "" },
            { "src": Npe6, "caption": "" },
            { "src": Npe7, "caption": "" },
            { "src": Npe8, "caption": "" },
            { "src": Npe9, "caption": "" },
            { "src": Npe10, "caption": "" },
            { "src": Npe11, "caption": "" },
            { "src": Npe12, "caption": "" },
            { "src": Npe13, "caption": "" },
            { "src": Npe14, "caption": "" },
            { "src": Npe15, "caption": "" },
            { "src": Npe16, "caption": "" },
            { "src": Npe17, "caption": "" },
            { "src": Npe18, "caption": "" },
            { "src": Npe19, "caption": "" },
            { "src": Npe20, "caption": "" },
            { "src": Npe21, "caption": "" },
            { "src": Npe22, "caption": "" },
            { "src": Npe23, "caption": "" },
            { "src": Npe24, "caption": "" },
            { "src": Npe25, "caption": "" },
            { "src": Npe26, "caption": "" },
            { "src": Npe27, "caption": "" },
            { "src": Npe28, "caption": "" },
            { "src": Npe29, "caption": "" },
            { "src": Npe30, "caption": "" },
            { "src": Npe31, "caption": "" },
            { "src": Npe32, "caption": "" },
            { "src": Npe33, "caption": "" },
            { "src": Npe34, "caption": "" },
            { "src": Npe35, "caption": "" },
            { "src": Npe36, "caption": "" },
            { "src": Npe37, "caption": "" },
            { "src": Npe38, "caption": "" },
            { "src": Npe39, "caption": "" },
            { "src": Npe40, "caption": "" },
            { "src": Npe41, "caption": "" },
            { "src": Npe42, "caption": "" },
            { "src": Npe43, "caption": "" },
        ]
    },
    {
        id: 2,
        name: "",
        designation: "Civil, Structural & Architectural",
        profilePic: ThumbNpc,
        images: [
            { "src": Npc1, "caption": "" },
            { "src": Npc2, "caption": "" },
            { "src": Npc3, "caption": "" },
            { "src": Npc4, "caption": "" },
            { "src": Npc5, "caption": "" },
            { "src": Npc6, "caption": "" },
            { "src": Npc7, "caption": "" },
            { "src": Npc8, "caption": "" },
            { "src": Npc9, "caption": "" },
            { "src": Npc10, "caption": "" },
            { "src": Npc11, "caption": "" },
            { "src": Npc12, "caption": "" },
            { "src": Npc13, "caption": "" },
            { "src": Npc14, "caption": "" },
            { "src": Npc15, "caption": "" },
            { "src": Npc16, "caption": "" },
            { "src": Npc17, "caption": "" },
            { "src": Npc18, "caption": "" },
            { "src": Npc19, "caption": "" },
            { "src": Npc20, "caption": "" },
            { "src": Npc21, "caption": "" },
            { "src": Npc22, "caption": "" },
            { "src": Npc23, "caption": "" },
            { "src": Npc24, "caption": "" },
            { "src": Npc25, "caption": "" },
            { "src": Npc26, "caption": "" },
            { "src": Npc27, "caption": "" },
            { "src": Npc28, "caption": "" },
            { "src": Npc29, "caption": "" },
            { "src": Npc30, "caption": "" },
            { "src": Npc31, "caption": "" },
            { "src": Npc32, "caption": "" },
            { "src": Npc33, "caption": "" },
        ]
    },
    {
        "id": 3,
        "name": "",
        "designation": "Mechanical, Piping Solutions & Heavy Liftings",
        "profilePic": ThumbNpis,
        "images": [
            { "src": Npis1, "caption": "" },
            { "src": Npis2, "caption": "" },
            { "src": Npis3, "caption": "" },
            { "src": Npis4, "caption": "" },
            { "src": Npis5, "caption": "" },
            { "src": Npis6, "caption": "" },
            { "src": Npis7, "caption": "" },
            { "src": Npis8, "caption": "" },
            { "src": Npis9, "caption": "" },
            { "src": Npis10, "caption": "" },
            { "src": Npis11, "caption": "" },
            { "src": Npis12, "caption": "" },
            { "src": Npis13, "caption": "" },
            { "src": Npis14, "caption": "" },
            { "src": Npis15, "caption": "" },
            { "src": Npis16, "caption": "" },
            { "src": Npis17, "caption": "" },
            { "src": Npis18, "caption": "" },
            { "src": Npis19, "caption": "" },
            { "src": Npis20, "caption": "" },
            { "src": Npis21, "caption": "" },
            { "src": Npis22, "caption": "" },
            { "src": Npis23, "caption": "" },
            { "src": Npis24, "caption": "" },
            { "src": Npis25, "caption": "" },
        ]
    },
    {
        id: 4,
        name: "",
        designation: "Electrical & Consultancy Services",
        profilePic: ThumbNps,
        images: [
            { src: Nps1, caption: "" },
            { src: Nps2, caption: "" },
            { src: Nps3, caption: "" },
            { src: Nps4, caption: "" },
            { src: Nps5, caption: "" },
            { src: Nps6, caption: "" },
            { src: Nps7, caption: "" },
            { src: Nps8, caption: "" },
            { src: Nps9, caption: "" },
            { src: Nps10, caption: "" },
            { src: Nps11, caption: "" },
            { src: Nps12, caption: "" },
            { src: Nps13, caption: "" },
            { src: Nps14, caption: "" },
            { src: Nps15, caption: "" },
            { src: Nps16, caption: "" },
            { src: Nps17, caption: "" },
            { src: Nps18, caption: "" },
            { src: Nps19, caption: "" },
        ]
    },
]

export default TeamData;