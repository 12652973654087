import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import SectionTitle from "../components/UI/SectionTitle";

const CLIENT_DATA = [
  {
    title: "Adabi Consumer Industries",
    img: require("../assets/img/clients/adabi.png"),
  },
  { title: "CADBURY", img: require("../assets/img/clients/cadbury.png") },
  {
    title: "Kellogg's cereal Malaysia",
    img: require("../assets/img/clients/kelloggs.png"),
  },
  {
    title: "weir minerals malaysia",
    img: require("../assets/img/clients/wehr.png"),
  },
  {
    title: "Linatex Rubber Products Sdn Bhd",
    img: require("../assets/img/clients/linatex.png"),
  },
  {
    title: "FGV Holdings Berhad",
    img: require("../assets/img/clients/fgv.png"),
  },
  {
    title: "FGV IFFCO SDN BHD",
    img: require("../assets/img/clients/fgv_iffco.png"),
  },
  {
    title: "IFFCO (Malaysia) SDN BHD",
    img: require("../assets/img/clients/iffco.png"),
  },
  {
    title: "gas malaysia",
    img: require("../assets/img/clients/gas_malaysia.png"),
  },
  { title: "sime darby", img: require("../assets/img/clients/sime_darby.png") },
  {
    title: "Tenaga Nasional Berhad",
    img: require("../assets/img/clients/tnb.png"),
  },
  { title: "BlueScope", img: require("../assets/img/clients/bluescope.png") },
  { title: "Safran", img: require("../assets/img/clients/safran.png") },
  { title: "cargill", img: require("../assets/img/clients/cargill.png") },
  {
    title: "tan chong motor",
    img: require("../assets/img/clients/tan_chong.png"),
  },
  { title: "Life", img: require("../assets/img/clients/life.png") },
  { title: "Aryzta", img: require("../assets/img/clients/arizta.png") },
  {
    title: "westport malaysia",
    img: require("../assets/img/clients/westport.png"),
  },
  {
    title: "northport malaysia",
    img: require("../assets/img/clients/northport.png"),
  },
  { title: "etika", img: require("../assets/img/clients/etika.png") },
  {
    title: "PT Synergy Oil Nusantara Indonesia",
    img: require("../assets/img/clients/pt_synergy.png"),
  },
  { title: "sonoco", img: require("../assets/img/clients/sonoco.png") },
  { title: "HT", img: require("../assets/img/clients/ht.png") },
  {
    title: "Commonwealth Handling Equipment Pool",
    img: require("../assets/img/clients/chep.png"),
  },
  {
    title: "WRP Asia Pacific",
    img: require("../assets/img/clients/wrp.png"),
  },
  {
    title: "United Plantations Berhad",
    img: require("../assets/img/clients/up.png"),
  },
  {
    title: "Rockwell automation",
    img: require("../assets/img/clients/rockwell.png"),
  },
  { title: "Mattel", img: require("../assets/img/clients/mattel.png") },
  {
    title: "Mondelez International",
    img: require("../assets/img/clients/mondelez.png"),
  },
  {
    title: "KVC Industrial Supplies Sdn Bhd",
    img: require("../assets/img/clients/kvc.jpg"),
  },
  { title: "CBRE malaysia", img: require("../assets/img/clients/cbre.png") },
  { title: "SKF", img: require("../assets/img/clients/skf.png") },
  { title: "interflour", img: require("../assets/img/clients/interflour.png") },
  { title: "lion group", img: require("../assets/img/clients/lion_group.png") },
  {
    title: "groupdesmet ballestra",
    img: require("../assets/img/clients/desmet_ballestra.png"),
  },
  { title: "ford", img: require("../assets/img/clients/ford.png") },
  {
    title: "globetronic",
    img: require("../assets/img/clients/globetronic.png"),
  },
  {
    title: "iDeimension",
    img: require("../assets/img/clients/ideminsion.png"),
  },
  { title: "toto", img: require("../assets/img/clients/toto.jpg") },
  { title: "steris", img: require("../assets/img/clients/steris.png") },
  {
    title: "saint-gobain",
    img: require("../assets/img/clients/saint_gobain.png"),
  },
  { title: "ngo energy", img: require("../assets/img/clients/ngo_energy.png") },
  {
    title: "J. Roger Preston (Malaysia) Sdn. Bhd",
    img: require("../assets/img/clients/jrp.png"),
  },
  {
    title: "Aluminium Company of Malaysia Bhd",
    img: require("../assets/img/clients/alcom.png"),
  },
  {
    title: "Kobelco Construction Machinery Malaysia Sdn Bhd",
    img: require("../assets/img/clients/kmct.png"),
  },
];

const OurClient = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/clients/our_client_banner.jpg")}
        title="OUR CLIENTS"
      />

      <div className="col-12 text-center sp-top-wt">
        <SectionTitle
          title="List of Clients"
          heading="We have Strategy for<br/> Serving Things Right "
        />
      </div>

      <div className="row" style={{ margin: 60 }}>
        {CLIENT_DATA.map((client) => (
          <div className="col-sm-3">
            <div className="client-item">
              <img src={client.img} alt="ServiceThumb" />
              <p className="title">{client.title}</p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default OurClient;
