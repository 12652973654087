import React from 'react';
import SectionTitle from "../UI/SectionTitle";
import safety from '../../assets/img/core-value/safety.png';
import trust from '../../assets/img/core-value/trust.png';
import confident from '../../assets/img/core-value/confident.png';
import teamwork from '../../assets/img/core-value/teamwork.png';
import accountability from '../../assets/img/core-value/accountability.png';
import quality from '../../assets/img/core-value/quality.png';

const CoreValue = () => {
    return (
        <div className="sp-y">
            <div className="container">
                <div className="col-12 text-center">
                    <SectionTitle
                        title="Our Belief & Core Values"
                        heading="We believe in always pushing <br/>towards excellence and further."
                    />
                </div>
                <div className="row mtn-20" style={{ alignItems: 'center' }}>
                    <div className="col-lg-2 col-md-6">
                        <img src={safety} alt="safety" style={{ width: '100px' }} />
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <img src={trust} alt="trust" style={{ width: '130px' }} />
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <img src={confident} alt="confident" style={{ width: '130px' }} />
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <img src={teamwork} alt="teamwork" style={{ width: '130px' }} />
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <img src={accountability} alt="safety" style={{ width: '130px' }} />
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <img src={quality} alt="quality" style={{ width: '150px' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoreValue;