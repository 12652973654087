import React, { Fragment } from 'react';

const ContactInfo = ({
    addressHQ,
    addressPenang,
    addressKlang,
    addressIndonesia,
    address
}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>HQ</span>
                    <span>{addressHQ}</span>
                    <span style={{ fontWeight: 'bold', textDecoration: 'underline'  }}>Klang Branch</span>
                    <span>{addressKlang}</span>
                    <span style={{ fontWeight: 'bold', textDecoration: 'underline'  }}>Penang Branch</span>
                    <span>{addressPenang}</span>
                    <span style={{ fontWeight: 'bold', textDecoration: 'underline'  }}>Indonesia Branch</span>
                    <span>{addressIndonesia}</span>
                    {/* <div className="wasap-contact-us">
                        <a href={`https://api.whatsapp.com/send?phone=60126972467&source=&data=&app_absent`}>
                            <i className="fa fa-whatsapp" />  <span className='contact-name-light'>Ir. Navingdren Maniam - Electrical</span>
                        </a><br />
                        <a href={`https://api.whatsapp.com/send?phone=60102172467&source=&data=&app_absent`}>
                            <i className="fa fa-whatsapp" />  <span className='contact-name-light'>Prakash Vijaya Kumar - Civil</span>
                        </a><br />
                        <a href={`https://api.whatsapp.com/send?phone=60166122467&source=&data=&app_absent`}>
                            <i className="fa fa-whatsapp" />  <span className='contact-name-light'>Thanabala Singgam - Mechanical</span>
                        </a>
                    </div> */}
                </address>
            </div>
        </Fragment>
    );
};

export default ContactInfo;