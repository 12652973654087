import React, { Fragment } from 'react';

import Header from '../components/Header'
import Slider from '../components/Slider/home-two'
import About from '../components/About/home-two'
import Services from '../components/Services'
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import CompanyGroup from "../components/CompanyGroup";
import CoreValue from "../components/CoreValue";

const HomeTwo = () => {
    return (
        <Fragment>
            <Header />
            <Slider />
            <About />
            <Services />
            <CompanyGroup />
            <CoreValue />
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default HomeTwo;