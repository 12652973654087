import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/service/thumb.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/service/service_banner.png')}
                title="OUR SERVICES"
                content=""
            />
            <PageAbout
                title={'Our Services'}
                heading="Provide best <br/> Engineering Solutions"
                thumb={ServiceThumb}
                content="
                <b>Nation Power Group</b> always try to provide the best Engineering Solutions for Clients. 
                Nation Power Group currently has a combined dedicated team who possess expertise in various 
                engineering sectors for industries such as Oil & Gas, Oleo Chemicals, Palm oil refineries, Oil Mills, Heavy industries, Rolling mills, Food and beverages, Semiconductors, Power Utilities and Commercial Buildings."
            />
            <Services classes="sm-y" />
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default PageService;