import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/page";
import TeamMember from '../templates/Team'
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/gallery/thumb_ourproject.jpg'

const PageTeam = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/project_banner.png')}
                title="OUR PROJECTS"
                content=""
            />
            <About
                title={''}
                heading="Delivering projects,<br/> from start to finish"
                thumb={ServiceThumb}
                content="<b>Nation Power Group</b> always try to provide the best Engineering Solutions for Clients to grow up their Engineering very sharply and smoothly."
            />
            <TeamMember />
            <Footer />
            <LoginRegister />
            <MobileMenu />
        </Fragment>
    );
};

export default PageTeam;