import React from "react";

import SectionTitle from "../UI/SectionTitle";

const CompanyGroup = () => {
  return (
    <div className="bg-offwhite demo-section sm-top sp-y" id="demo-area">
      <div className="container">
        <div className="col-12 text-center">
          <SectionTitle
            title="Group of Company"
            heading="Providing our client, <br/> a total engineering solution"
            // tagline="More over <span class='tag-no'>1900+</span><strong>customer</strong>"
          />
        </div>
        <div className="row mtn-20">
          <div className="col-sm-4">
            <div className="demo-item">
              {/* <Link to={`${process.env.PUBLIC_URL + "/home-two"}`} className="image" target="_blank">
                                <img src={home1} alt="Demo Images" />
                                <i className="fa fa-long-arrow-right" />
                            </Link> */}
              <h4 className="title">
                <a
                  // href={`${process.env.PUBLIC_URL + "/"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NATION POWER ENGINEERING <br /> SDN BHD
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "400",
                      textTransform: "initial",
                    }}
                  >
                    <br />
                    Electrical, Instrumentation, Automation, Industrial Wiring,
                    Maintenance Works
                  </span>
                </a>
              </h4>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="demo-item">
              {/* <Link to={`${process.env.PUBLIC_URL + "/home-two"}`} className="image" target="_blank">
                                <img src={home2} alt="Demo Images" />
                                <i className="fa fa-long-arrow-right" />
                            </Link> */}
              <h4 className="title">
                <a
                  // href={`${process.env.PUBLIC_URL + "/"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NATION POWER CONSTRUCTIONS <br /> SDN BHD
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "400",
                      textTransform: "initial",
                    }}
                  >
                    <br />
                    Civil, Structural & Architecture Works
                  </span>
                </a>
              </h4>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="demo-item">
              {/* <a href={`${process.env.PUBLIC_URL + "/"}`} className="image" target="_blank" rel="noopener noreferrer">
                                <img src={coming} alt="Demo Images" />
                                <i className="fa fa-long-arrow-right" />
                            </a> */}
              <h4 className="title">
                <a
                  // href={`${process.env.PUBLIC_URL + "/"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NATION POWER INDUSTRIAL SOLUTIONS SDN BHD
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "400",
                      textTransform: "initial",
                    }}
                  >
                    <br />
                    Industrial Piping, Stainless Stee & Mild Steel Fabrication,
                    Heavy Lifting & Moving
                  </span>
                </a>
              </h4>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4">
              <div className="demo-item">
                {/* <a href={`${process.env.PUBLIC_URL + "/"}`} className="image" target="_blank" rel="noopener noreferrer">
                                    <img src={coming} alt="Demo Images" />
                                    <i className="fa fa-long-arrow-right" />
                                </a> */}
                <h4 className="title">
                  <a
                    // href={`${process.env.PUBLIC_URL + "/"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NATION POWER SERVICES <br /> SDN BHD
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "400",
                        textTransform: "initial",
                      }}
                    >
                      <br />
                      Consultancy, Project Management, Competent & Service
                      Engineers, Energy Manager (REEM), HV&LV Apparatus
                      Servicing & Testing
                    </span>
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="demo-item">
                {/* <a href={`${process.env.PUBLIC_URL + "/"}`} className="image" target="_blank" rel="noopener noreferrer">
                                    <img src={coming} alt="Demo Images" />
                                    <i className="fa fa-long-arrow-right" />
                                </a> */}
                <h4 className="title">
                  <a
                    // href={`${process.env.PUBLIC_URL + "/"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PT TENAGA NASIONAL TEKNIK <br /> (Indonesia)
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "400",
                        textTransform: "initial",
                      }}
                    >
                      <br />
                      Civil, Structural & Architecture Works
                    </span>
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="demo-item">
                {/* <a href={`${process.env.PUBLIC_URL + "/"}`} className="image" target="_blank" rel="noopener noreferrer">
                                    <img src={coming} alt="Demo Images" />
                                    <i className="fa fa-long-arrow-right" />
                                </a> */}
                <h4 className="title">
                  <a
                    // href={`${process.env.PUBLIC_URL + "/"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NATION POWER (S) Pte Ltd <br /> (Singapore)
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "400",
                        textTransform: "initial",
                      }}
                    >
                      <br />
                      Civil, Structural & Architecture Works
                    </span>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyGroup;
