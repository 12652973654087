import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import suruhanjaya from "../../assets/img/suruhanjaya.png";
import cidb from "../../assets/img/cidb.png";

function Footer() {
  return (
    <>
      <footer className="sp-bottom " style={{ backgroundColor: "#e6f7ff" }}>
        <div className="container">
          <div className="row mtn-40">
            <div className="col-md-4 col-lg-3 ml-auto">
              <Widget title="Services">
                <List classes="widget-list">
                  <LI>
                    <Link
                      to={`${
                        process.env.PUBLIC_URL + "/service/electrical-work?id=1"
                      }`}
                    >
                      Electrical & Instrumentation
                    </Link>
                  </LI>
                  <LI>
                    <Link
                      to={`${
                        process.env.PUBLIC_URL + "/service/civil-work?id=2"
                      }`}
                    >
                      Civil, Structural & Architectural
                    </Link>
                  </LI>
                  <LI>
                    <Link
                      to={`${
                        process.env.PUBLIC_URL + "/service/mechanical-work?id=3"
                      }`}
                    >
                      Mechanical, Piping Solutions & Heavy Liftings
                    </Link>
                  </LI>
                  <LI>
                    <Link
                      to={`${
                        process.env.PUBLIC_URL +
                        "/service/consultancy-services?id=4"
                      }`}
                    >
                      Electrical & Consultancy Services
                    </Link>
                  </LI>
                </List>
              </Widget>
            </div>

            <div className="col-md-4 col-lg-5 ml-auto">
              <Widget title="Group Of Company">
                <List classes="widget-list">
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      Nation Power Engineering SDN BHD (959406-K)
                    </Link>
                  </LI>
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      Nation Power Constructions SDN BHD (1404702-P)
                    </Link>
                  </LI>
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      Nation Power Industrial Solutions SDN BHD (1179277-M)
                    </Link>
                  </LI>
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      Nation Power Services SDN BHD (1181590-V)
                    </Link>
                  </LI>
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      PT Tenaga Nasional Teknik (Indonesia)
                    </Link>
                  </LI>
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}`} target={"_blank"}>
                      Nation Power (S) Pte Ltd (Singapore)
                    </Link>
                  </LI>
                </List>
              </Widget>
            </div>

            <div className="col-md-7 col-lg-4">
              <Widget title="Contact Us">
                <address>
                  <span style={{ fontWeight: "bold" }}>HQ</span>
                  <br />
                  No.7, Jalan Spring 34/33 Seksyen 34, 40470, Shah Alam,
                  Selangor Darul Ehsan, Malaysia
                  <br />
                  <i className="fa fa-phone" />{" "}
                  <span className="contact-name">03-51672467 </span>
                  <br />
                  <i className="fa fa-fax" />{" "}
                  <span className="contact-name">03-51672466 </span>
                  <br />
                  <i className="fa fa-envelope" />{" "}
                  <a
                    href="mailto:enquiries@nationpower.com.my"
                    className="contact-name"
                  >
                    enquiries@nationpower.com.my
                  </a>
                  <br />
                  <div className="wasap">
                    <a
                      href={`https://api.whatsapp.com/send?phone=60126972467&source=&data=&app_absent`}
                    >
                      <i className="fa fa-whatsapp" />{" "}
                      <span className="contact-name">
                        Ir. Navingdren Maniam{" "}
                        <span style={{ fontSize: "12px" }}>(Electrical)</span>{" "}
                      </span>
                    </a>
                    <br />
                    <a
                      href={`https://api.whatsapp.com/send?phone=60102172467&source=&data=&app_absent`}
                    >
                      <i className="fa fa-whatsapp" />{" "}
                      <span className="contact-name">
                        Prakash Vijaya Kumar{" "}
                        <span style={{ fontSize: "12px" }}>
                          (Civil, Structural & Architectural)
                        </span>
                      </span>
                    </a>
                    <br />
                    <a
                      href={`https://api.whatsapp.com/send?phone=60166122467&source=&data=&app_absent`}
                    >
                      <i className="fa fa-whatsapp" />{" "}
                      <span className="contact-name">
                        Thanabala Singgam{" "}
                        <span style={{ fontSize: "12px" }}>(Mechanical)</span>{" "}
                      </span>
                    </a>
                  </div>
                </address>
              </Widget>
            </div>
          </div>
        </div>
      </footer>
      <div
        className="row d-flex justify-content-around justify-content-md-around align-items-center"
        style={{ backgroundColor: "#e6f7ff" }}
      >
        <div>
          <img src={suruhanjaya} style={{ width: 200 }} alt="Suruhanjaya" />
        </div>
        <div>
          <img src={cidb} style={{ width: 200 }} alt="CIDB" />
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          backgroundColor: "#e6f7ff",
          padding: 20,
        }}
      >
        <center>
          <Text classes="copyright-txt">
            &copy; {new Date().getFullYear()} Nation Power Group. All Rights
            Reserved.
          </Text>
        </center>
      </div>
    </>
  );
}

export default Footer;
