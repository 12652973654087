import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

import safety from '../assets/img/core-value/safety.png';
import trust from '../assets/img/core-value/trust.png';
import confident from '../assets/img/core-value/confident.png';
import teamwork from '../assets/img/core-value/teamwork.png';
import accountability from '../assets/img/core-value/accountability.png';
import quality from '../assets/img/core-value/quality.png';

const CoreValue = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/core-value/corevalue_banner.png')}
                title="OUR BELIEFS & CORE VALUES"
                content=""
            />
            <div className="sp-y">
                <PageAbout
                    title=''
                    position='left'
                    heading="SAFETY"
                    icon={safety}
                    content="
                Safety serves as a barometer of our company’s overall success and is 
                a specific measure of our operating excellence."
                />
                <PageAbout
                    title=''
                    heading="TRUST"
                    icon={trust}
                    content="
                Trust is the mutual respect for and confidence in people. 
                Trust recognizes the importance of individuals and appreciates 
                their diverse opinions. 
                Trust compels us to share information and encourage new ideas."
                />
                <PageAbout
                    title=''
                    position='left'
                    heading="CONFIDENCE"
                    icon={confident}
                    content="
                Self-confident people take initiative, 
                handle the unexpected, stand behind their 
                convictions and support the effort of others. 
                We take bold, innovative, creative actions, 
                capitalize on opportunities, make sound decisions 
                quickly, and mobilize the best resources for rapid action."
                />
                <PageAbout
                    title=''
                    heading="TEAMWORK"
                    icon={teamwork}
                    content="
                Teamwork is a personal involvement and collaboration in a team environment.
                It includes setting a common goal in support of business objectives, 
                making an individual committed to the team’s success and recognizing 
                the success of the team."
                />
                <PageAbout
                    title=''
                    position='left'
                    heading="ACCOUNTABILITY"
                    icon={accountability}
                    content="
                Being accountable means every employee assumes ownership and 
                responsibility for his or her own work, regardless of the job 
                they perform. Being accountable means making decisions and
                holding oneself responsible for the consequences of those choices."
                />
                <PageAbout
                    title=''
                    heading="QUALITY"
                    icon={quality}
                    content="
                Quality is the primary determinant of customer satisfaction and loyalty.
                This requires the employees to continually provide internal and external 
                customers with the right product or service. In today’s increasingly 
                competitive business environment, better quality translates into better 
                value for our customers. This is the very essence of competitive 
                differentiation."
                />
            </div>
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default CoreValue;