import React,{Fragment} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <ServiceDetails/>
            <Footer/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageServiceDetails;