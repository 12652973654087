import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import SectionTitle from "../components/UI/SectionTitle";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/Sidebar/SidebarItem";
import List from "../components/UI/List";
import Anchor from "../components/UI/Anchor";
import LI from "../components/UI/List/Item";

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about/aboutUsBanner.png")}
        title="ABOUT US"
        // content="Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <PageWrapper classes="sp-y">
        <div className="col-lg-8 col-xl-8 text-center">
          <SectionTitle variant="dark" heading="Our Story" />
          <p style={{ textAlign: "justify" }}>
            Nation Power Group is an engineering firm providing
            multidisciplinary services in Malaysia, Indonesia, and Singapore. We
            are Class G7 and Class A contractors registered under Construction
            Industry Development Board (CIDB) and Suruhanjaya Tenaga
            respectively.
            <br />
            <br />
            Nation Power Group was founded in 2011 with its origin in Electrical
            Engineering. Since then, we have evolved and expand to include
            businesses in Civil & Structural, Mechanical and Engineering
            Services to better serve the needs of growing number of clients in
            Malaysia and neighbouring countries. The successful and timely
            completion of projects along with our focus driven approach and
            utmost priority towards client’s needs enable us to deliver numerous
            remarkable projects locally and internationally.
            <br />
            <br />
            Throughout our operation for about a decade we have a proven track
            record of successful business by not compromising our core values
            and commitment to our clients. Nation Power Group strength lies in
            our professional workforce with diverse skills experience and
            dedication who are committed to deliver exceptional services to our
            clients.
            <br />
            <br />
            Nation Power Group consist of:
            <ul>
              <li>- NATION POWER ENGINEERING SDN BHD (959406-K)</li>
              <li>- NATION POWER CONSTRUCTIONS SDN BHD (1404702-P)</li>
              <li>- NATION POWER INDUSTRIAL SOLUTIONS SDN BHD (1179277-M)</li>
              <li>- NATION POWER SERVICES SDN BHD (1181590-V)</li>
              <li>- PT TENAGA NASIONAL TEKNIK (Indonesia)</li>
              <li>- NATION POWER (S) PTE LTD (Singapore)</li>
            </ul>
            <br />
            <br />
            We take pride for executing projects to highest quality standard and
            maintaining cordial business relationship with all our clients.
            <br />
            <br />
            We at Nation Power Group promise the certainty and comfort that our
            client’s projects are in capable and experienced hands. As we
            continue to grow from projects to projects, we firmly believe we are
            ready to bring innovative solutions to this rapidly growing
            industry.
          </p>
        </div>
        <Sidebar classes={"col-lg-4"}>
          <SidebarItem title="Download Brochure">
            <List classes="service-list">
              <LI>
                <Anchor
                  path={require("../assets/NPG_PROFILE.pdf")}
                  target="_blank"
                >
                  <i className="fa fa-file-pdf-o" />
                  Company Profile
                </Anchor>
              </LI>
            </List>
          </SidebarItem>
        </Sidebar>
      </PageWrapper>

      <div
        style={{ backgroundColor: "rgba(0, 37, 68, 0.9)", padding: 100 }}
        id="mission"
      >
        <div className="container">
          <div
            className="row align-items-lg-center"
            style={{ color: "white", textAlign: "justify" }}
          >
            <div className="col-md-6 col-lg-6 order-1">
              <div className="about-content ml-0">
                <h4 style={{ color: "white" }}>Mission</h4>
                <p>
                  We are committed towards quality excellence, value engineering
                  and safety creating company. To strive and to deliver projects
                  exceeding clients’ expectations.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 order-1">
              <div className="about-content ml-0">
                <h4 style={{ color: "white" }}>Vision</h4>
                <p>
                  Engineering is the major influencer in improving our quality
                  of life. There will be a continuous need for pr oducts and
                  services to harness and implement this. Nation Power Group
                  will strive to be best in class as a distributor of quality
                  products and services and a responsible corporate citizen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
