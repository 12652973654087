import React from 'react';
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Our HQ Office</h3>
                                <div className="iFrameContainer">
                                    <iframe
                                        class="responsiveIframe"
                                        title="Location Official Office Nation Power Group"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.3006157439145!2d101.51437195043925!3d3.0136329548001504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdad87c3d60107%3A0x60b382befac16531!2sNation%20Power%20Group!5e0!3m2!1sen!2smy!4v1641338870262!5m2!1sen!2smy"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-information contact-method">
                                <div className="contact-info-con">
                                    <h3>Contact Info</h3>
                                    <ContactInfo
                                        addressHQ="No.7, Jalan Spring 34/33 Seksyen 34, 40470, Shah Alam, Selangor Darul Ehsan, Malaysia"
                                        addressPenang="No.11A-2, Jalan Vernea 1, Aspen Vision City, 14110 Simpang Ampat, Penang, Malaysia"
                                        addressKlang="No.16 & 18, Jalan Kebun Nenas 1C/KS7, Bandar Putera, 41000 Klang Selangor Darul Ehsan, Malaysia"
                                        addressIndonesia="Komplek Ruko Citra Nusa Niaga Blok E2 No 12, Kota Batam Kode Pos 29466, Kepulauan Riau, Indonesia"
                                        address="- No.7, Jalan Spring 34/33 Seksyen 34, 40470, Shah Alam, Selangor Darul Ehsan, Malaysia <br />"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;